export const PREVIEW_DOMAINS = Object.freeze({
  /* eslint-disable-next-line hubspot-dev/prefer-hubspot-url-utils */
  local: 'local.hubspotqa.com',
  prod: 'preview.hs-sites.com',
  qa: 'preview.hs-sitesqa.com'
});
export const PrimaryUsageTypes = Object.freeze({
  PRIMARY_FOR_LANDING: 'PRIMARY_FOR_LANDING',
  PRIMARY_FOR_WEBSITE: 'PRIMARY_FOR_WEBSITE',
  PRIMARY_FOR_BLOG: 'PRIMARY_FOR_BLOG',
  PRIMARY_FOR_KNOWLEDGE_BASE: 'PRIMARY_FOR_KNOWLEDGE_BASE',
  PRIMARY_FOR_EMAIL: 'PRIMARY_FOR_EMAIL',
  PRIMARY_FOR_CUSTOMER_PORTAL: 'PRIMARY_FOR_CUSTOMER_PORTAL',
  PRIMARY_FOR_EMAIL_TRACKING: 'PRIMARY_FOR_EMAIL_TRACKING'
});
export const SecondaryUsageTypes = Object.freeze({
  USED_FOR_LANDING: 'USED_FOR_LANDING',
  USED_FOR_WEBSITE: 'USED_FOR_WEBSITE',
  USED_FOR_BLOG: 'USED_FOR_BLOG',
  USED_FOR_KNOWLEDGE_BASE: 'USED_FOR_KNOWLEDGE_BASE',
  USED_FOR_EMAIL: 'USED_FOR_EMAIL',
  USED_FOR_CUSTOMER_PORTAL: 'USED_FOR_CUSTOMER_PORTAL',
  USED_FOR_EMAIL_TRACKING: 'USED_FOR_EMAIL_TRACKING'
});
export const CONTENT_EDIT_DOMAIN_USAGE_TYPES = Object.freeze(Object.assign({}, PrimaryUsageTypes, SecondaryUsageTypes));