/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-this-alias */

function mandatory(name) {
  throw new Error(`Required parameter '${name}' was not provided`);
}
export default class NavItem {
  constructor(parentId, {
    id = mandatory('id'),
    label = mandatory('label'),
    children = [],
    path,
    isBeta = false,
    isNew = false,
    sidebarMatchRegexes = [],
    sidebarExclusionRegexes = []
  }) {
    if (!children.length && typeof path === 'undefined') {
      throw new Error('path is required when children are not provided');
    }
    if (path && !id) {
      throw new Error('trackingScreen is required for all links');
    }
    if (!Array.isArray(children)) {
      throw new Error('children must be an array');
    }
    this.sidebarMatchRegexes = sidebarMatchRegexes;
    this.sidebarExclusionRegexes = sidebarExclusionRegexes;
    this.id = id;
    this.label = label;
    this.settingsGroup = parentId || id;
    this.trackingScreen = id;
    this.isBeta = isBeta;
    this.isNew = isNew;
    this.path = path && path.replace(/\/$/, '');
    this.children = children.map(child => new NavItem(id, Object.assign({}, child)));
  }
  matchesPath(path) {
    if (this.children.length) {
      return this.children.some(child => child.matchesPath(path));
    }
    if (!this.path || !path) {
      return false;
    }

    // check exclusions regex
    if (this.sidebarExclusionRegexes && this.sidebarExclusionRegexes.length > 0 && this.sidebarExclusionRegexes.some(regex => new RegExp(regex).test(path))) {
      return false;
    }

    // match regex
    if (this.sidebarMatchRegexes && this.sidebarMatchRegexes.length > 0 && this.sidebarMatchRegexes.some(regex => new RegExp(regex).test(path))) {
      return true;
    }
    const splitHref = this.path.split('/');
    const splitPath = path.split('/');
    return splitPath.length >= splitHref.length && splitHref.every((hrefComponent, index) => hrefComponent === splitPath[index]);
  }
  getHrefMatchingPath(path) {
    if (this.children.length) {
      const matchingChild = this.children.find(child => child.matchesPath(path));
      return matchingChild ? matchingChild.path || null : null;
    } else if (this.path && path.indexOf(this.path) === 0) {
      return this.path;
    }
    return null;
  }
  findMatchingItem(path) {
    let match;
    if (this.children.length > 0) {
      this.children.some(navItem => {
        match = navItem.findMatchingItem(path);
        if (match) {
          return true;
        }
        return false;
      });
    } else if (this.matchesPath(path)) {
      match = this;
    }
    return match;
  }
}