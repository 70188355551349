'use es6';

import { combineReducers } from 'redux';
import Teams from 'ContentData/reducers/Teams';
import Auth from './Auth';
import Brands from './domains/Brands';
import Domains from './domains/Domains';
import DomainWizard from './domains/DomainWizard';
import DomainTeams from './domains/DomainTeams';
import Notifications from 'ReduxMessenger/reducers/Notifications';
import EditDomain from './domains/EditDomain';
import EmailSendingDomains from './domains/EmailSendingDomains';
import TrackedDomains from './domains/TrackedDomains';
import DomainConnect from './domains/DomainConnect';
import Redirects from './redirects/Redirects';
import AddRedirect from './redirects/AddRedirect';
import EditRedirect from './redirects/EditRedirect';
import HubUsers from './redirects/HubUsers';
import Import from './redirects/Import';
import DomainAlerts from './domains/DomainAlerts';
import DomainSecuritySettings from './domains/DomainSecuritySettings';
import DowngradeNotifications from './domains/DowngradeNotifications';
import BusinessUnits from './domains/BusinessUnits';
import SslVerification from './domains/SslVerification';
import { dedicatedIPv2Slice } from '../slices/DedicatedIPv2';
import { entriSlice } from '../slices/Entri';
export const rootReducer = combineReducers({
  auth: Auth,
  brands: Brands,
  domains: Domains,
  domainWizard: DomainWizard,
  domainTeams: DomainTeams,
  notifications: Notifications,
  editDomain: EditDomain,
  emailSendingDomains: EmailSendingDomains,
  trackedDomains: TrackedDomains,
  domainConnect: DomainConnect,
  redirects: Redirects,
  addRedirect: AddRedirect,
  editRedirect: EditRedirect,
  hubUsers: HubUsers,
  importRedirects: Import,
  domainAlerts: DomainAlerts,
  domainSecuritySettings: DomainSecuritySettings,
  downgradeNotifications: DowngradeNotifications,
  businessUnits: BusinessUnits,
  resources: combineReducers({
    teams: Teams
  }),
  sslVerification: SslVerification,
  dedicatedIPv2: dedicatedIPv2Slice.reducer,
  entri: entriSlice.reducer
});
export const initialState = rootReducer(undefined, {
  type: 'APP_STARTED'
});
export default rootReducer;