import { createStore, applyMiddleware, compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import thunk from 'redux-thunk';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../r... Remove this comment to see the full error message
import rootReducer from '../reducers';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Redu... Remove this comment to see the full error message
import NotificationMiddleware from 'ReduxMessenger/middleware/NotificationMiddleware';
export function useAppDispatch() {
  return useDispatch();
}
export function useAppSelector(selector) {
  return useSelector(selector);
}
const createStoreWithMiddleware = compose(applyMiddleware(thunk), applyMiddleware(NotificationMiddleware), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
// @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
)(createStore);
export default function configureStore(initialState) {
  return createStoreWithMiddleware(rootReducer, initialState);
}