'use es6';

import Immutable from 'immutable';
import * as ActionTypes from '../../actions/ActionTypes';
import DEFAULT_STATE from '../../data/domains/DomainWizard';
import { DEFAULT_DOMAINS_PRIMARY_LANGUAGE, DEFAULT_DOMAINS_SUFFIX, DEFAULT_EMPTY_SUBDOMAIN, DEFAULT_EMPTY_SUBDOMAIN_VALIDATION_ERROR_LIST, DEFAULT_FETCH_PRIMARY_LANGUAGES_REQUEST_STATUS, DEFAULT_SUBDOMAIN_VALIDATION_STATE, RequestStatus, ValidationState } from '../../Constants';
import { getIsDomainSetupComplete } from '../../utils/domains/getIsDomainSetupComplete';
import { initMapWithDefaultValue, getApexDomainValidationState, getIsEmailSendingDomainConnected } from '../../utils/domains/Domains';
import { getBrandNameWithSuffix, getFullDomainName } from '../../utils/domains/Brands';
import { getDomainSuffix } from '../../utils/domains/Brands';
import { getSubdomainFromInput } from '../../utils/domains/Brands';
function addSetupDomainUsageType(setupDomainUsageTypes, newSetupDomainUsageType) {
  return setupDomainUsageTypes.includes(newSetupDomainUsageType) ? setupDomainUsageTypes : setupDomainUsageTypes.push(newSetupDomainUsageType);
}
function removeSetupDomainUsageType(setupDomainUsageTypes, removedSetupDomainUsageType) {
  return setupDomainUsageTypes.filter(domainUsageType => domainUsageType !== removedSetupDomainUsageType);
}

/**
 * Sets all rows to a given value using existing keys
 *
 * @param {*} state - uses state to check what current keys are
 * @param {*} value - updated value
 * @returns new map w/ old keys but new values
 */
function applyToAllRows(state, value) {
  const domainRowKeys = [...state.get('subdomainNames').keys()];
  return initMapWithDefaultValue(domainRowKeys, value);
}

/**
 * Resets all SubdomainPicker row values to their defaults
 * Uses args as keys
 *
 * @param {*} domainRowKeys - keys for all row maps
 * @returns
 */
function initAllDomainRowMaps(domainRowKeys) {
  return {
    subdomainNames: initMapWithDefaultValue(domainRowKeys, DEFAULT_EMPTY_SUBDOMAIN),
    suffixes: initMapWithDefaultValue(domainRowKeys, DEFAULT_DOMAINS_SUFFIX),
    subdomainValidationStates: initMapWithDefaultValue(domainRowKeys, DEFAULT_SUBDOMAIN_VALIDATION_STATE),
    subdomainValidationErrorLists: initMapWithDefaultValue(domainRowKeys, DEFAULT_EMPTY_SUBDOMAIN_VALIDATION_ERROR_LIST),
    primaryLanguages: initMapWithDefaultValue(domainRowKeys, DEFAULT_DOMAINS_PRIMARY_LANGUAGE),
    fetchPrimaryLanguagesRequestStatus: initMapWithDefaultValue(domainRowKeys, DEFAULT_FETCH_PRIMARY_LANGUAGES_REQUEST_STATUS)
  };
}

/**
 * Updates all SubdomainPickerRow row values
 * Takes into account the existing inputtedDomainName and other parts of current state
 *
 * @param {*} domainRowKeys - keys for all row maps
 * @param {*} state - the current Redux state
 */
function updateAllDomainRowMaps(domainRowKeys, state) {
  const domain = state.get('inputtedDomainNameOrEmailAddress');
  const brand = state.get('brandName');
  const suffix = getDomainSuffix(domain);
  const subdomainName = getSubdomainFromInput(domain, brand, suffix);
  const domainSubmissionState = state.get('domainSubmissionState');
  return Object.assign({}, initAllDomainRowMaps(domainRowKeys), {
    subdomainNames: initMapWithDefaultValue(domainRowKeys, subdomainName),
    suffixes: initMapWithDefaultValue(domainRowKeys, suffix),
    subdomainValidationStates: initMapWithDefaultValue(domainRowKeys, domainSubmissionState)
  });
}

/**
 * Will return true if another row shares same subdomain + suffix
 * This means these rows will eventually become the same domain
 *
 * @param domainRowKey - row that user modified
 * @param compareDomainRowKey - potentially synced row
 * @returns boolean
 */
function getHasMatchingSubdomainAndSuffix(state, domainRowKey, compareDomainRowKey) {
  return state.getIn(['suffixes', compareDomainRowKey]) === state.getIn(['suffixes', domainRowKey]) && state.getIn(['subdomainNames', compareDomainRowKey]) === state.getIn(['subdomainNames', domainRowKey]);
}

/**
 * Used to update DomainWizard map fields
 * Updates both the modified row's value AND any synced rows
 *
 * @param domainWizardField - name of updated map (e.g. primaryLanguages)
 * @param domainRowKey - row that user modified (e.g. "blog")
 * @param newValue - updated value (e.g. "en-hk")
 * @returns updated field
 */
function updateFieldForAllSyncedRows(state, domainWizardField, domainRowKey, newValue) {
  return state.get(domainWizardField).map((currentValue, compareDomainRowKey) => {
    return getHasMatchingSubdomainAndSuffix(state, domainRowKey, compareDomainRowKey) ? newValue : currentValue;
  });
}

/**
 * returns true (stale) if the current user-entered value does not match what we validated
 */
function getIsValidationStale(state, domainRowKey, validatedDomainName) {
  const currentSubdomain = state.get('subdomainNames').get(domainRowKey);
  const currentSuffix = state.get('suffixes').get(domainRowKey);
  const currentBrand = state.get('brandName');
  const currentDomainName = getFullDomainName(currentBrand, currentSuffix, currentSubdomain);
  const isValidationStale = currentDomainName !== validatedDomainName;
  return isValidationStale;
}
export default function DomainWizard(state = DEFAULT_STATE, action) {
  const {
    type,
    inputtedDomainNameOrEmailAddress,
    isApex,
    emailDomain,
    errorType,
    errorList,
    newSetupDomainUsageType,
    removedSetupDomainUsageType,
    contentTypes,
    domainRowKey,
    subdomain,
    suffix,
    newDomainIds,
    newDedicatedIPRootDomain,
    domainReplacements,
    taskId,
    setupTaskCategory,
    dnsProviderInfo,
    redirectTarget,
    domainLanguage,
    brandName,
    customError,
    domains,
    dnsRecords,
    spfRecords,
    spfCheckStatus,
    dmarcRecords,
    dmarcCheckStatus,
    isDkimConnected,
    dnsSetupState,
    verificationEndingTime,
    finalStepScreen,
    brandToReplace,
    replacementBrand,
    oldDomainNames,
    isConnectingReplacementBrand,
    validatedDomainName,
    syncFlowUrlsMap,
    domainChangeId,
    status,
    useBuiltInCdn,
    targetedDomainUsageType,
    enabled,
    isValidSetup,
    reverseProxyValidationResponses,
    isUngatedForSpfAndDmarc,
    isEmailSendingDomain
  } = action;
  switch (type) {
    case ActionTypes.VALIDATE_DOMAIN_ATTEMPTED:
      return state.merge({
        domainValidationRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.VALIDATE_DOMAIN_SUCCEEDED:
      return state.merge({
        domainValidationRequestStatus: RequestStatus.UNINITIALIZED,
        domainSubmissionState: getApexDomainValidationState(isApex),
        domainSubmissionErrorList: Immutable.List(),
        subdomainNames: applyToAllRows(state, subdomain || DEFAULT_EMPTY_SUBDOMAIN),
        suffixes: applyToAllRows(state, suffix || DEFAULT_DOMAINS_SUFFIX),
        subdomainValidationStates: applyToAllRows(state, getApexDomainValidationState(isApex)),
        subdomainValidationErrorLists: applyToAllRows(state, DEFAULT_EMPTY_SUBDOMAIN_VALIDATION_ERROR_LIST),
        brandName
      });
    case ActionTypes.VALIDATE_DOMAIN_FAILED:
      return state.merge({
        domainValidationRequestStatus: RequestStatus.UNINITIALIZED,
        domainSubmissionState: errorType,
        domainSubmissionErrorList: errorList,
        suffixes: suffix ? applyToAllRows(state, suffix) : state.get('suffixes')
      });
    case ActionTypes.VALIDATE_SUBDOMAIN_PICKER_ROW_SUCCEEDED:
      // Ignore validation result if stale (current value has already changed)
      return getIsValidationStale(state, domainRowKey, validatedDomainName) ? state : state.merge({
        subdomainValidationStates: state.get('subdomainValidationStates').set(domainRowKey, getApexDomainValidationState(isApex)),
        subdomainValidationErrorLists: state.get('subdomainValidationErrorLists').set(domainRowKey, [])
      });
    case ActionTypes.VALIDATE_SUBDOMAIN_PICKER_ROW_FAILED:
      // Ignore validation result if stale (current value has already changed)
      return getIsValidationStale(state, domainRowKey, validatedDomainName) ? state : state.merge({
        subdomainValidationStates: state.get('subdomainValidationStates').set(domainRowKey, errorType),
        subdomainValidationErrorLists: state.get('subdomainValidationErrorLists').set(domainRowKey, errorList)
      });
    case ActionTypes.ADD_DOMAINS_ATTEMPTED:
      return state.merge({
        addDomainsRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.CreateReverseProxyActions.creationSucceeded:
      return state.merge({
        addDomainsRequestStatus: RequestStatus.SUCCEEDED
      });
    case ActionTypes.ADD_DOMAINS_SUCCEEDED:
      return state.merge({
        newContentHostingDomainIds: newDomainIds,
        taskId,
        dnsRecords,
        addDomainsRequestStatus: RequestStatus.SUCCEEDED,
        setupTaskCategory
      });
    case ActionTypes.ADD_DOMAINS_FAILED:
    case ActionTypes.ADD_DOMAIN_AFTER_PURCHASE_FAILED:
    case ActionTypes.CreateReverseProxyActions.creationFailed:
      return state.merge({
        addDomainsRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.ADD_EMAIL_DOMAIN_ATTEMPTED:
    case ActionTypes.CreateReverseProxyActions.creationStarted:
      return state.merge({
        addDomainsRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.ADD_EMAIL_DOMAIN_SUCCEEDED:
      return state.merge({
        emailDomainForHostingSetup: emailDomain,
        addDomainsRequestStatus: RequestStatus.SUCCEEDED,
        dnsRecords,
        spfRecords,
        spfCheckStatus,
        dmarcRecords,
        dmarcCheckStatus,
        isDkimConnected
      });
    case ActionTypes.ADD_DEDICATED_IP_ATTEMPTED:
      return state.merge({
        addDomainsRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.ADD_DEDICATED_IP_SUCCEEDED:
      return state.merge({
        addDomainsRequestStatus: RequestStatus.SUCCEEDED,
        newDedicatedIPRootDomain
      });
    case ActionTypes.ADD_DEDICATED_IP_FAILED:
      // Deliberately set to UNINITIALIZED,
      // to prevent user from navigating to Hosting Setup step if api fails.
      return state.merge({
        addDomainsRequestStatus: RequestStatus.UNINITIALIZED
      });
    case ActionTypes.FETCH_TASK_DOMAINS_ATTEMPTED:
      return state.merge({
        taskDomainsRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.FETCH_TASK_EMAIL_DOMAIN_ATTEMPTED:
      return state.merge({
        taskDomainsRequestStatus: RequestStatus.PENDING,
        verifyTaskDomainsRequestStatus: RequestStatus.PENDING,
        customError: Immutable.Map({})
      });
    case ActionTypes.FETCH_TASK_DOMAINS_SUCCEEDED:
      return state.merge({
        newContentHostingDomainIds: newDomainIds,
        taskDomainsRequestStatus: RequestStatus.SUCCEEDED,
        taskId,
        dnsRecords,
        dnsSetupState,
        setupTaskCategory,
        useBuiltInCdn
      });
    case ActionTypes.FETCH_TASK_EMAIL_DOMAIN_SUCCEEDED:
      return state.merge({
        emailDomainForHostingSetup: emailDomain,
        taskDomainsRequestStatus: RequestStatus.SUCCEEDED,
        verifyTaskDomainsRequestStatus: RequestStatus.SUCCEEDED,
        isVerifyTaskDomainsRequestSucceeded: true,
        customError: Immutable.Map({}),
        isSetupComplete: getIsEmailSendingDomainConnected({
          domain: emailDomain,
          isUngatedForSpfAndDmarc
        }),
        dnsRecords,
        spfRecords,
        spfCheckStatus,
        dmarcRecords,
        dmarcCheckStatus,
        isDkimConnected
      });
    case ActionTypes.FETCH_DEDICATED_IP_DNS_RECORDS_ATTEMPTED:
      return state.merge({
        taskDomainsRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.FETCH_DEDICATED_IP_DNS_RECORDS_SUCCEEDED:
      return state.merge({
        taskDomainsRequestStatus: RequestStatus.SUCCEEDED,
        dnsRecords
      });
    case ActionTypes.FETCH_DEDICATED_IP_DNS_RECORDS_FAILED:
      return state.merge({
        taskDomainsRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.VERIFY_DEDICATED_IP_DNS_RECORDS_ATTEMPTED:
      return state.merge({
        verifyTaskDomainsRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.VERIFY_DEDICATED_IP_DNS_RECORDS_SUCCEEDED:
      return state.merge({
        verifyTaskDomainsRequestStatus: RequestStatus.SUCCEEDED,
        isVerifyTaskDomainsRequestSucceeded: true,
        dnsRecords
      });
    case ActionTypes.VERIFY_DEDICATED_IP_DNS_RECORDS_FAILED:
      return state.merge({
        verifyTaskDomainsRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.FETCH_VERIFY_TASK_EMAIL_DOMAIN_ATTEMPTED:
    case ActionTypes.FETCH_VERIFY_TASK_DOMAINS_ATTEMPTED:
      return state.merge({
        verifyTaskDomainsRequestStatus: RequestStatus.PENDING,
        customError: Immutable.Map({})
      });
    case ActionTypes.FETCH_VERIFY_TASK_EMAIL_DOMAIN_FAILED:
      return state.merge({
        verifyTaskDomainsRequestStatus: RequestStatus.FAILED,
        isVerifyDomainsRequestInReattemptingMode: true
      });
    case ActionTypes.FETCH_VERIFY_TASK_DOMAINS_FAILED:
      return state.merge({
        verifyTaskDomainsRequestStatus: RequestStatus.FAILED,
        isVerifyDomainsRequestInReattemptingMode: true,
        customError
      });
    case ActionTypes.RESET_VERIFY_TASK_DOMAINS_REQUEST_STATUS:
      return state.merge({
        verifyTaskDomainsRequestStatus: RequestStatus.UNINITIALIZED,
        customError: Immutable.Map({})
      });
    case ActionTypes.FETCH_VERIFY_TASK_DOMAINS_SUCCEEDED:
      return state.merge({
        dnsRecords,
        verifyTaskDomainsRequestStatus: RequestStatus.SUCCEEDED,
        isVerifyTaskDomainsRequestSucceeded: true,
        customError: Immutable.Map({}),
        isSetupComplete: domains.first() ? domains.every(getIsDomainSetupComplete) : false,
        reverseProxyValidationResponses
      });
    case ActionTypes.FETCH_VERIFY_TASK_EMAIL_DOMAIN_SUCCEEDED:
      return state.merge({
        emailDomainForHostingSetup: emailDomain,
        verifyTaskDomainsRequestStatus: RequestStatus.SUCCEEDED,
        isVerifyTaskDomainsRequestSucceeded: true,
        dnsRecords,
        spfRecords,
        spfCheckStatus,
        dmarcRecords,
        dmarcCheckStatus,
        isDkimConnected
      });
    /**
     * Stages a new usage type selection
     *
     * Re-inits all Domain Wiz map keys using updated usage types
     * (e.g. subdomainNames, primaryLanguages, suffixes, validation, etc.)
     */
    case ActionTypes.ADD_SETUP_DOMAIN_USAGE_TYPE:
      return state.merge(Object.assign({
        setupDomainUsageTypes: addSetupDomainUsageType(state.get('setupDomainUsageTypes'), newSetupDomainUsageType)
      }, initAllDomainRowMaps(addSetupDomainUsageType(state.get('setupDomainUsageTypes'), newSetupDomainUsageType)), updateAllDomainRowMaps(addSetupDomainUsageType(state.get('setupDomainUsageTypes'), newSetupDomainUsageType), state)));
    /**
     * Removes a staged usage type selection
     *
     * Re-inits all Domain Wiz map keys using updated usage types
     * (e.g. subdomainNames, primaryLanguages, suffixes, validation, etc.)
     */
    case ActionTypes.REMOVE_SETUP_DOMAIN_USAGE_TYPE:
      return state.merge(Object.assign({
        setupDomainUsageTypes: removeSetupDomainUsageType(state.get('setupDomainUsageTypes'), removedSetupDomainUsageType)
      }, initAllDomainRowMaps(removeSetupDomainUsageType(state.get('setupDomainUsageTypes'), removedSetupDomainUsageType)), updateAllDomainRowMaps(removeSetupDomainUsageType(state.get('setupDomainUsageTypes'), removedSetupDomainUsageType), state)));
    // Note: Now only used for Dedicated IP!
    // Use add/remove setupDomainUsageType actions above instead
    case ActionTypes.UPDATE_CONTENT_TYPE:
      return state.merge(contentTypes);
    case ActionTypes.UPDATE_DOMAIN_NAME:
      return state.merge({
        inputtedDomainNameOrEmailAddress,
        domainSubmissionState: ValidationState.INITIAL
      });
    case ActionTypes.UPDATE_REDIRECT_TARGET:
      return state.merge({
        redirectTarget
      });
    case ActionTypes.UPDATE_SUBDOMAIN_STRING:
      return state.merge({
        subdomainNames: state.get('subdomainNames').set(domainRowKey, subdomain)
      });
    case ActionTypes.UPDATE_SUBDOMAIN_PRIMARY_LANGUAGE:
      return state.set('primaryLanguages', updateFieldForAllSyncedRows(state, 'primaryLanguages', domainRowKey, domainLanguage));
    case ActionTypes.FETCH_DOMAIN_LANGUAGE_ATTEMPTED:
      return state.set('fetchPrimaryLanguagesRequestStatus', updateFieldForAllSyncedRows(state, 'fetchPrimaryLanguagesRequestStatus', domainRowKey, RequestStatus.PENDING));
    case ActionTypes.FETCH_DOMAIN_LANGUAGE_SUCCEEDED:
      return state.merge({
        primaryLanguages: updateFieldForAllSyncedRows(state, 'primaryLanguages', domainRowKey, domainLanguage),
        fetchPrimaryLanguagesRequestStatus: updateFieldForAllSyncedRows(state, 'fetchPrimaryLanguagesRequestStatus', domainRowKey, RequestStatus.SUCCEEDED)
      });
    case ActionTypes.FETCH_DOMAIN_LANGUAGE_FAILED:
      return state.set('fetchPrimaryLanguagesRequestStatus', updateFieldForAllSyncedRows(state, 'fetchPrimaryLanguagesRequestStatus', domainRowKey, RequestStatus.FAILED));
    case ActionTypes.UPDATE_SUFFIX_STRING:
      return state.merge({
        suffixes: state.get('suffixes').set(domainRowKey, suffix),
        inputtedDomainNameOrEmailAddress: getBrandNameWithSuffix(state.get('brandName'), suffix)
      });
    // Only used in the Scope Controlled Add Primary wizard
    case ActionTypes.UPDATE_ALL_SUBDOMAIN_STRINGS:
      return state.merge({
        subdomainNames: applyToAllRows(state, subdomain)
      });
    /**
     * Called when:
     * 1. Editing Scope Controlled subdomain/suffix picker
     * 2. Choosing an existing validated brand domain and advancing
     *    to the SubdomainPicker step
     */
    case ActionTypes.UPDATE_ALL_SUFFIX_STRINGS:
      return state.merge({
        suffixes: applyToAllRows(state, suffix),
        inputtedDomainNameOrEmailAddress: getBrandNameWithSuffix(state.get('brandName'), suffix)
      });
    case ActionTypes.RESET_DOMAIN_WIZARD:
      return DEFAULT_STATE;
    case ActionTypes.CHECK_DNS_PROVIDER_ATTEMPTED:
      return state.merge({
        dnsProviderRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.CHECK_DNS_PROVIDER_SUCCEEDED:
      {
        return state.merge({
          dnsProviderRequestStatus: RequestStatus.SUCCEEDED,
          dnsProvider: dnsProviderInfo.get('dnsProvider'),
          taskApexDomain: dnsProviderInfo.get('apexDomain'),
          domainConnect: Immutable.Map({
            providerSupportsDomainConnect: !isEmailSendingDomain && dnsProviderInfo.get('providerSupportsDomainConnect')
          }),
          synchronousDomainConnect: state.get('synchronousDomainConnect').merge({
            providerSupportsSynchronousFlow: !isEmailSendingDomain && dnsProviderInfo.get('providerSupportsSynchronousFlow')
          })
        });
      }
    case ActionTypes.CHECK_DNS_PROVIDER_FAILED:
      return state.merge({
        dnsProviderRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.SynchronousDomainConnectActions.getDnsRecordUrlsAttempted:
      return state.merge({
        synchronousDomainConnect: state.get('synchronousDomainConnect').merge({
          dnsRecordUrlsRequestStatus: RequestStatus.PENDING
        })
      });
    case ActionTypes.SynchronousDomainConnectActions.getDnsRecordUrlsSucceeded:
      return state.merge({
        synchronousDomainConnect: state.get('synchronousDomainConnect').merge({
          dnsRecordUrlsRequestStatus: RequestStatus.SUCCEEDED,
          syncFlowUrlsMap: state.getIn(['synchronousDomainConnect', 'syncFlowUrlsMap']).merge(syncFlowUrlsMap)
        })
      });
    case ActionTypes.SynchronousDomainConnectActions.getDnsRecordUrlsFailed:
      return state.merge({
        synchronousDomainConnect: state.get('synchronousDomainConnect').merge({
          dnsRecordUrlsRequestStatus: RequestStatus.FAILED
        })
      });
    case ActionTypes.SynchronousDomainConnectActions.getDnsRecordUrlsForSslPreProvisioningFailed:
      return state.merge({
        synchronousDomainConnect: state.get('synchronousDomainConnect').merge({
          dnsRecordUrlsRequestStatus: RequestStatus.SUCCEEDED,
          dnsRecordUrlsRequestStatusForSslPreProvisioning: RequestStatus.FAILED
        })
      });
    case ActionTypes.SynchronousDomainConnectActions.updateRecordStatus:
      return state.setIn(['synchronousDomainConnect', 'syncFlowUrlsMap', domainChangeId, 'status'], status);
    case ActionTypes.UPDATE_FINAL_STEP_SCREEN:
      return state.merge({
        finalStepScreen
      });
    case ActionTypes.ENABLE_DID_UTILIZE_DOMAIN_CONNECT:
      return state.merge({
        didUtilizeDomainConnect: true
      });
    case ActionTypes.UPDATE_VERIFICATION_ENDING_TIME:
      return state.merge({
        verificationEndingTime
      });
    // When users select their brand to replace, we setup 1 SubdomainPicker row per old domain
    // We also reset the SubdomainPicker's staged data (subdomains, suffixes, validation states, etc.)
    case ActionTypes.BRAND_TO_REPLACE_SELECTED:
      return state.merge({
        brandToReplace,
        oldDomainNames
      }, initAllDomainRowMaps(oldDomainNames));
    case ActionTypes.NEW_BRAND_SELECTED:
      return state.merge({
        replacementBrand,
        isConnectingReplacementBrand
      });
    case ActionTypes.POPULATE_REPLACE_BRANDS_FIELDS_FROM_SETUP_TASK:
      return state.merge({
        brandToReplace,
        replacementBrand,
        domainReplacements,
        isConnectingReplacementBrand
      });
    case ActionTypes.REPLACE_BRAND_ATTEMPTED:
      return state.merge({
        replaceBrandRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.REPLACE_BRAND_FAILED:
      return state.merge({
        replaceBrandRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.REPLACE_BRAND_SUCCEEDED:
      return state.merge({
        brandToReplace: '',
        replacementBrand: '',
        replaceBrandRequestStatus: RequestStatus.SUCCEEDED
      });
    case ActionTypes.CreateReverseProxyActions.builtInCdnToggled:
      return state.merge({
        useBuiltInCdn
      });
    case ActionTypes.AutomaticRedirectActions.initialize:
      return state.merge({
        automaticRedirect: state.get('automaticRedirect').merge({
          isValidSetup,
          targetedDomainUsageType,
          subdomain,
          defaultSubdomain: subdomain,
          enabled
        })
      });
    case ActionTypes.AutomaticRedirectActions.update:
      return state.merge({
        automaticRedirect: state.get('automaticRedirect').merge({
          subdomain,
          enabled
        })
      });
    case ActionTypes.AutomaticRedirectActions.reset:
      return state.merge({
        automaticRedirect: DEFAULT_STATE.get('automaticRedirect')
      });
    default:
      return state;
  }
}