import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
const initialState = {
  entriConfiguration: null,
  entriCloseEventDetail: null
};
export const entriSlice = createSlice({
  name: 'entri',
  initialState,
  reducers: {
    setEntriCloseEventDetail: (state, action) => produce(state, draft => {
      draft.entriCloseEventDetail = action.payload;
    }),
    setEntriConfiguration: (state, action) => produce(state, draft => {
      draft.entriConfiguration = action.payload;
    }),
    resetEntriState: state => produce(state, () => initialState)
  }
});
export const {
  setEntriCloseEventDetail,
  setEntriConfiguration,
  resetEntriState
} = entriSlice.actions;