'use es6';

import Immutable from 'immutable';
import { RequestStatus, ValidationState, AddDomainWizardFinalStepScreens, SpfRecordsStatusCheckDefaultValue, DmarcStatusCheckDefaultValue, IsDkimConnectedDefaultValue } from '../../Constants';
import { DNS_SETUP_REQUIRED } from '../../enums/domains/DnsSetupState';
export default Immutable.Map({
  domainSubmissionState: ValidationState.INITIAL,
  domainSubmissionErrorList: Immutable.List(),
  inputtedDomainNameOrEmailAddress: '',
  emailDomainForHostingSetup: Immutable.Map({
    domain: '',
    dkimRecords: [],
    emailSendingDomainState: Immutable.Map({
      state: null,
      connected: false
    })
  }),
  sslValidationEmailRequestStatus: RequestStatus.UNINITIALIZED,
  domainValidationRequestStatus: RequestStatus.UNINITIALIZED,
  taskDomainsRequestStatus: RequestStatus.UNINITIALIZED,
  verifyTaskDomainsRequestStatus: RequestStatus.UNINITIALIZED,
  isVerifyDomainsRequestInReattemptingMode: false,
  isVerifyTaskDomainsRequestSucceeded: false,
  setupDomainUsageTypes: Immutable.List(),
  redirectTarget: null,
  newContentHostingDomainIds: Immutable.List(),
  newDedicatedIPRootDomain: '',
  setupTaskCategory: '',
  domainReplacements: Immutable.Map({}),
  addDomainsRequestStatus: RequestStatus.UNINITIALIZED,
  oldDomainNames: Immutable.List(),
  subdomainNames: Immutable.Map({}),
  primaryLanguages: Immutable.Map({}),
  fetchPrimaryLanguagesRequestStatus: Immutable.Map({}),
  suffixes: Immutable.Map({}),
  brandName: '',
  subdomainValidationStates: Immutable.Map({}),
  subdomainValidationErrorLists: Immutable.Map({}),
  taskId: '',
  taskApexDomain: '',
  dnsProvider: '',
  dnsProviderRequestStatus: RequestStatus.UNINITIALIZED,
  domainConnect: Immutable.Map({
    providerSupportsDomainConnect: false
  }),
  synchronousDomainConnect: Immutable.Map({
    providerSupportsSynchronousFlow: false,
    dnsRecordUrlsRequestStatus: RequestStatus.UNINITIALIZED,
    dnsRecordUrlsRequestStatusForSslPreProvisioning: RequestStatus.UNINITIALIZED,
    syncFlowUrlsMap: Immutable.Map({})
  }),
  customError: Immutable.Map({}),
  isSetupComplete: false,
  domains: Immutable.List(),
  dnsRecords: Immutable.List(),
  spfRecords: Immutable.List(),
  dmarcRecords: Immutable.List(),
  spfCheckStatus: SpfRecordsStatusCheckDefaultValue,
  dmarcCheckStatus: DmarcStatusCheckDefaultValue,
  isDkimConnected: IsDkimConnectedDefaultValue,
  dnsSetupState: DNS_SETUP_REQUIRED,
  verificationEndingTime: null,
  finalStepScreen: AddDomainWizardFinalStepScreens.HOSTING_STEPS_V2,
  brandToReplace: '',
  replacementBrand: '',
  replaceBrandRequestStatus: RequestStatus.UNINITIALIZED,
  isConnectingReplacementBrand: false,
  /**
   * @deprecated - rename to an "automatic DNS setup" boolean for Entri and Domain Connect
   */
  didUtilizeDomainConnect: false,
  useBuiltInCdn: true,
  automaticRedirect: Immutable.Map({
    isValidSetup: undefined,
    targetedDomainUsageType: undefined,
    subdomain: undefined,
    defaultSubdomain: undefined,
    enabled: undefined
  }),
  reverseProxyValidationResponses: Immutable.List()
});