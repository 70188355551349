module.exports = {
  "pageView": {
    "name": "pageview",
    "class": "view",
    "namespace": "Settings",
    "properties": {
      "screen": "string"
    }
  },
  "securitySettingsView": {
    "name": "security-settings-view",
    "class": "view",
    "namespace": "Settings",
    "properties": {
      "screen": "string"
    }
  },
  "primaryDomainTypeButtonView": {
    "name": "primary-domain-type-button-view",
    "class": "view",
    "properties": {
      "enabled": {
        "type": "boolean"
      },
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "securitySettingsCheckedInteraction": {
    "name": "domain-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "CONTENT_SECURITY_POLICY",
        "CONTENT_SECURITY_POLICY_REPORT_ONLY",
        "X_FRAME_OPTIONS",
        "REFERRER_POLICY",
        "FEATURE_POLICY",
        "PERMISSIONS_POLICY",
        "X_CONTENT_TYPE_OPTIONS",
        "X_XSS_PROTECTION",
        "HTTPS",
        "STRICT_TRANSPORT_SECURITY"
      ],
      "checked": "boolean",
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "securitySettingsConfiguredSettingInteraction": {
    "name": "domain-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "CONTENT_SECURITY_POLICY",
        "CONTENT_SECURITY_POLICY_REPORT_ONLY",
        "X_FRAME_OPTIONS",
        "REFERRER_POLICY",
        "FEATURE_POLICY",
        "PERMISSIONS_POLICY",
        "X_XSS_PROTECTION",
        "HTTPS",
        "STRICT_TRANSPORT_SECURITY"
      ],
      "configured-setting": [
        "policy-directives",
        "enable-nonce",
        "enable-preload",
        "include-subdomains",
        "tls-version",
        "xss-setting",
        "directives",
        "directive",
        "duration"
      ],
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "domainInteraction": {
    "name": "domain-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "userReadOnly": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "domainBusinessUnitInteraction": {
    "name": "domain-interaction",
    "class": "interaction",
    "properties": {
      "business_unit_id_string": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "select-business-unit"
      ],
      "businessUnitId": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "connectDomainInteraction": {
    "name": "domain-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-verify-cname",
        "clicked-next-step",
        "clickedContinueSetup",
        "added-domains",
        "completed-long-load",
        "entered-add-domain-flow"
      ],
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "taskId": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": [
          "PRIMARY_CREATE",
          "PRIMARY_REPLACE",
          "SECONDARY",
          "REDIRECT"
        ],
        "isOptional": true
      },
      "isApex": {
        "type": "boolean",
        "isOptional": true
      },
      "isReverseProxy": {
        "type": "boolean",
        "isOptional": true
      },
      "success": {
        "type": "boolean",
        "isOptional": true
      },
      "step": {
        "type": "string",
        "isOptional": true
      },
      "automaticRedirectSubdomain": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "brandInteraction": {
    "name": "brand-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "added-brand",
        "deleted-brand",
        "replaced-brand"
      ],
      "replacementType": {
        "type": [
          "existing-redirect",
          "new-brand"
        ],
        "isOptional": true
      },
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "preProvisionSslInteraction": {
    "name": "pre-provision-ssl-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "openedModal",
        "attemptedVerification"
      ],
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "clickedConnectDomainInteraction": {
    "name": "domain-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "zero-state": "string",
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "clickedPurchaseDomainInteraction": {
    "name": "domain-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "source": "string",
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "removeDomainUsageType": {
    "name": "domain-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "remove-domain-usage-type"
      ],
      "disconnected": "boolean",
      "content-type": "string",
      "domain-type": "string",
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "selectContentTypeDomainInteraction": {
    "name": "domain-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "content-type": "string",
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "entriPageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "domainType": "string",
      "dns-provider": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "Settings"
  },
  "entriInteraction": {
    "name": "domain-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "domainType": "string",
      "dns-provider": "string",
      "success": {
        "type": "boolean",
        "isOptional": true
      },
      "setupType": {
        "type": "string",
        "isOptional": true
      },
      "provider": {
        "type": "string",
        "isOptional": true
      },
      "lastStatus": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "domainConnectInteraction": {
    "name": "domain-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "dns-provider": "string",
      "domain-connect-change-type": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "domainConnectView": {
    "name": "domain-interaction",
    "class": "view",
    "properties": {
      "dns-provider": [
        "GODADDY",
        "ONE_AND_ONE",
        "GOOGLE",
        "CLOUDFLARE"
      ],
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "findGoDaddyDomainInteraction": {
    "name": "domain-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "updatedAssignedTeamsDomainInteraction": {
    "name": "domain-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "createRedirect": {
    "name": "create-redirect",
    "class": "interaction",
    "properties": {
      "action": "string",
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "editRedirect": {
    "name": "edit-redirect",
    "class": "interaction",
    "properties": {
      "action": "string",
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "bulkEditRedirects": {
    "name": "bulk-edit-redirects",
    "class": "interaction",
    "properties": {
      "action": "string",
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "bulkDeleteRedirects": {
    "name": "bulk-delete-redirects",
    "class": "interaction",
    "properties": {
      "action": "string",
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "filteredRedirects": {
    "name": "filtered-redirects",
    "class": "interaction",
    "properties": {
      "filterBy": "string",
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "exportRedirects": {
    "name": "exported-redirects",
    "class": "interaction",
    "namespace": "Settings",
    "properties": {
      "screen": "string"
    }
  },
  "importRedirects": {
    "name": "import-redirects",
    "class": "interaction",
    "properties": {
      "action": "string",
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "dismissedDomainSslAlert": {
    "name": "dismissed-domain-ssl-alert-interaction",
    "class": "interaction",
    "properties": {
      "sslStatusType": "string",
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "clickedDomainSslAlertCta": {
    "name": "clicked-domain-ssl-alert-cta-interaction",
    "class": "interaction",
    "properties": {
      "sslStatusType": "string",
      "cta": "string",
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "viewDedicatedIP": {
    "name": "dedicated-ip-interaction",
    "class": "view",
    "properties": {
      "view": [
        "pql-view",
        "table-view"
      ],
      "marketingStage": {
        "type": "string",
        "isOptional": true
      },
      "transactionalStage": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "dedicatedIPWizardInteraction": {
    "name": "dedicated-ip-interaction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": [
          "launch-wizard",
          "navigate-to-step",
          "expand-dns-error",
          "wizard-completed"
        ]
      },
      "stepName": {
        "type": [
          "CONTENT_TYPE",
          "SELECT_DOMAIN",
          "REVIEW_URLS",
          "HOSTING_SETUP"
        ],
        "isOptional": true
      },
      "accountType": {
        "type": [
          "DEDICATED_MARKETING",
          "DEDICATED_TRANSACTIONAL",
          "BOTH"
        ],
        "isOptional": true
      },
      "marketingStage": {
        "type": "string",
        "isOptional": true
      },
      "transactionalStage": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "disconnectDedicatedIP": {
    "name": "dedicated-ip-interaction",
    "class": "interaction",
    "properties": {
      "accountType": [
        "DEDICATED_MARKETING",
        "DEDICATED_TRANSACTIONAL"
      ],
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "addedSystemRedirectDomain": {
    "name": "added-system-redirect-domain",
    "class": "interaction",
    "namespace": "Settings",
    "properties": {
      "screen": "string"
    }
  },
  "showLeaveWizardPageConfirmation": {
    "name": "show-leave-wizard-page-confirmation",
    "class": "interaction",
    "properties": {
      "action": [
        "show-leave-wizard-page-confirmation"
      ],
      "step": {
        "type": "string"
      },
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "confirmCloseWizard": {
    "name": "confirm-close-wizard",
    "class": "interaction",
    "properties": {
      "action": [
        "confirm-close-wizard"
      ],
      "button": [
        "closeButton",
        "keepConnecting",
        "cancelConnecting"
      ],
      "step": {
        "type": "string"
      },
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "reverseProxyValidationTool": {
    "name": "reverse-proxy-validation-tool",
    "class": "interaction",
    "properties": {
      "action": [
        "open-validation-tool",
        "click-learn-more",
        "open-check-details",
        "close-check-details",
        "refresh-checks",
        "close-validation-tool",
        "check-failed",
        "check-succeeded"
      ],
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "updateAutomaticRedirect": {
    "name": "domain-interaction",
    "class": "interaction",
    "properties": {
      "enabled": {
        "type": "boolean",
        "isOptional": true
      },
      "subdomain": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "sslProvisioningTool": {
    "name": "ssl-provisioning-tool",
    "class": "interaction",
    "properties": {
      "action": [
        "open-ssl-provisioning-tool",
        "close-wizard-with-success",
        "close-wizard-with-error"
      ],
      "screen": "string"
    },
    "namespace": "Settings"
  },
  "settingsNavPageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen2": {
        "type": "string",
        "isOptional": true
      },
      "settingsApp": {
        "type": "string",
        "isOptional": true
      },
      "optedIn": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "settingsNavInteraction": {
    "name": "settings-navigation",
    "class": "interaction",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "settingsGroup": "string",
      "settingsLink": "string",
      "settingsApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "settingsNavBackLink": {
    "name": "settings-navigation-back-link",
    "class": "interaction",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "settingsApp": {
        "type": "string",
        "isOptional": true
      },
      "backLocation": "string"
    },
    "namespace": "settingsui"
  }
};